import React from 'react';
import './HeroSection.css';
import '../App.css';

function HeroSection() {
  return (
    <div className='hero-container'>
        <h1>Adventure Awaits</h1>
        <p>Get Your License and Get On The Air</p>
        <p> </p>
        <p>Contact us for details on upcoming test sessions</p>
    </div>
  )
}

export default HeroSection