import React from 'react';
import '../../App.css';
import Footer from '../Footer'

function Schedule () {
    return (
        <>
        <div className='hz-mtn'>
            <section className='hz-mtn'>
                <p>
                    <h1>Test Schedule</h1> 
                </p>
            </section>
        </div>
        <div>
        <Footer/>
        </div>
        </>
        );
}

export default Schedule;