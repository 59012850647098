import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Practice from './components/pages/Practice';
import Schedule from './components/pages/Schedule';

function App() {
  return (
    <>
    <Router>
      <NavBar />
      <Switch>
        <Route path='/' exact component={Home}/>
        <Route path='/practice' component={Practice} />
        <Route path='/schedule' component={Schedule} />
      </Switch>
    </Router>
    </>
  );
}

export default App;
