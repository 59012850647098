import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import './Footer.css'

function Footer() {
  return (
    <div className='footer-container'>
        <div className='footer-links'>
        </div>
        <section className='social-media'>
            <div className='social-media-wrap'>
                <small className='website-rights'>HAM Zone VE Group Copyright 2020-2024</small>
            </div>
        </section>
    </div>
  )
}

export default Footer