import React from 'react';
import '../../App.css';
import Footer from '../Footer';

 function Practice () {
    return (
    <>
    <div className='hz-mtn'>
        <section className='hz-mtn'>
            <p>
                <h1>Practice Tests</h1> 
            </p>
        </section>
    </div>
    <div>
    <Footer/>
    </div>
    </>
    );
}

export default Practice;